// src/utils/toast.js
import { Toast } from 'vant';

export function showToast(message, duration = 3000) {
  Toast({
    message: message,
    duration: duration,
    onClose: () => {
      // 关闭提示后的操作
    }
  });
}

export function showErrorToast(message, duration = 5000) {
  Toast({
    message: message,
    duration: duration,
    onClose: () => {
      // 关闭提示后的操作
    }
  });
}