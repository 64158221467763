<template>
  <div id="app">
    <div class="header-container">
      <h1>图纸管理系统</h1>
    </div>
    <div class="input-container">
      <van-cell-group>
        <van-field v-model="username" label="用户名" placeholder="请输入用户名"></van-field>
        <van-field v-model="password" label="密码" placeholder="请输入密码" type="password"></van-field>
      </van-cell-group>
    </div>
    <div class="button-container">
      <van-button type="primary" @click="handleSubmit">登录</van-button>
    </div>
  </div>
</template>

<script>
import { Field, Button, CellGroup } from 'vant';
import axios from 'axios';
import { showErrorToast } from '@/utils/toast';

export default {
  components: {
    'van-field': Field,
    'van-button': Button,
    'van-cell-group': CellGroup
  },
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post('/api/login', {
          username: this.username,
          password: this.password
        });

        // 登录成功，存储用户信息
        localStorage.setItem('user', JSON.stringify(response.data));

        // 跳转到仪表板页面
        this.$router.push('/CategoryList');
      } catch (error) {
        if (error.response) {
          showErrorToast(error.response.data.message);
        } else {
          showErrorToast('登录时发生错误，请稍后再试');
        }
      }
    }
  }
};
</script>

<style scoped>
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

#app {
  text-align: center;
  width: 100%;
}

.header-container {
  height: 33.33vh;
  /* 屏幕高度的 1/3 */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  /* 背景颜色可自定义 */
}

.header-container h1 {
  font-size: 30px;
  /* 设置标题字体大小 */
}

.input-container {
  width: 90%;
  margin: 20px 0;
}

.button-container {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  /* 使按钮容器占据整个宽度 */
}

.button-container .van-button {
  width: 95%;
  /* 按钮宽度为屏幕宽度的 95% */
  text-align: center;
  background-color: #00008B;
  /* 深蓝色背景 */
  color: white;
  /* 文字颜色为白色 */
}
</style>