<template>
  <div class="breadcrumb-container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/CategoryList">所有文件</a>
        </li>
        <li v-for="(item, index) in breadcrumbItems" :key="item.id" class="breadcrumb-item">
          <a v-if="index < breadcrumbItems.length - 1 || isFileDetailPage" :href="`/CategoryList?parentCatID=${item.id}`">{{ item.catName }}</a>
          <span v-else>{{ item.catName }}</span>
        </li>
      </ol>
    </nav>
    <div class="view-toggle">
      <van-icon :name="isThumbnailView ? 'bars' : 'card'" @click="toggleViewMode" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbItems: {
      type: Array,
      default: () => []
    },
    isThumbnailView: {
      type: Boolean,
      default: true
    },
    isFileDetailPage: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleViewMode() {
      this.$emit('toggle-view-mode');
    }
  }
};
</script>

<style scoped>
.breadcrumb-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
  color: #6c757d;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #007bff;
}

.breadcrumb-item span {
  color: #6c757d;
}

.view-toggle {
  cursor: pointer;
}

.van-icon {
  font-size: 20px;
  color: #007bff;
}
</style>