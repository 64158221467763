import Vue from 'vue';
import App from './App.vue';
import Router from 'vue-router';
import axios from 'axios';
import store from './store';
import { isMobile } from './utils/device';  // 第一次导入
import Vant from 'vant';
import 'vant/lib/index.css';

import HelloWorld from './components/HelloWorld.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import CategoryList from './components/CategoryList.vue';
import FileList from './components/FileList.vue';
import FileInfo from './components/FileInfo.vue';
import CadViewer from './components/CadViewer.vue';

// 引入 Vant 图标库
import { Icon } from 'vant';
Vue.use(Vant);
Vue.use(Icon);
Vue.config.productionTip = false;
Vue.use(Router);
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const routes = [
  { path: '/', component: HelloWorld },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  {
    path: '/CategoryList', component: CategoryList,
    props: route => ({ parentCatID: route.query.parentCatID || 0 })
  },
  { path: '/FileList', component: FileList },
  {
    path: '/FileInfo/:fileId',
    name: 'FileInfo',
    component: FileInfo,
    props: true
  },
  {
    path: '/cad-viewer/:filepath',
    name: 'CadViewer',
    component: CadViewer,
    props: true // 允许将路由参数作为props传递
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

new Vue({
  router,
  store,
  render: h => h(App),
  // created() {
  //   if (isMobile()) {
  //     this.$router.push('/mobile/login');
  //   } else {
  //     this.$router.push('/pc/login');
  //   }
  // }
}).$mount('#app');

