<template>
    <div id="cad-viewer">
      <div ref="canvasContainer" class="canvas-container"></div>
      <div class="controls">
        <button @click="zoomIn">放大</button>
        <button @click="zoomOut">缩小</button>
        <button @click="exportImage">导出图形</button>
        <button @click="toggleLayer">切换图层</button>
      </div>
    </div>
  </template>
  
  <script>
  import * as THREE from 'three';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
  import { DXFLoader } from 'three-dxf';
  
  export default {
    props: {
      filepath: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        scene: null,
        camera: null,
        renderer: null,
        controls: null,
        dxfLoader: new DXFLoader(),
        scale: 1,
        layers: [],
        visibleLayers: [],
      };
    },
    methods: {
      init() {
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.$refs.canvasContainer.appendChild(this.renderer.domElement);
        
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.camera.position.z = 5;
  
        // 使用传入的filepath加载DXF文件
        this.loadDXF(this.filepath);
        
        this.animate();
      },
      loadDXF(filePath) {
        this.dxfLoader.load(filePath, (data) => {
          this.scene.add(data);
          this.layers = this.extractLayers(data);
          this.visibleLayers = this.layers; // 默认显示所有图层
        });
      },
      extractLayers(dxfData) {
        return [...new Set(dxfData.entities.map(entity => entity.layer))];
      },
      zoomIn() {
        this.scale *= 1.1;
        this.camera.zoom = this.scale;
        this.camera.updateProjectionMatrix();
      },
      zoomOut() {
        this.scale /= 1.1;
        this.camera.zoom = this.scale;
        this.camera.updateProjectionMatrix();
      },
      toggleLayer() {
        // 切换图层的逻辑
      },
      exportImage() {
        const link = document.createElement('a');
        link.download = 'cad-image.png';
        link.href = this.renderer.domElement.toDataURL();
        link.click();
      },
      animate() {
        requestAnimationFrame(this.animate);
        this.controls.update();
        this.renderer.render(this.scene, this.camera);
      }
    },
    mounted() {
      this.init();
    }
  };
  </script>
  
  <style>
  #cad-viewer {
    position: relative;
  }
  .canvas-container {
    width: 100%;
    height: 100vh;
  }
  .controls {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  </style>