<template>
  <div id="app">
    <div class="search-container">
      <van-search v-model="searchValue" placeholder="请输入搜索关键词" />
    </div>
    <div class="nav-container">
      <van-tabs v-model="activeTab">
        <van-tab title="标签1"></van-tab>
        <van-tab title="标签2"></van-tab>
        <van-tab title="标签3"></van-tab>
        <van-tab title="标签4"></van-tab>
      </van-tabs>
    </div>
    <div class="file-list">
      <div class="header">
        <div class="select">
          <van-checkbox v-model="selectAll" @change="toggleSelectAll"></van-checkbox>
        </div>
        <div class="filename">文件名</div>
        <div class="client">客户</div>
        <div class="datetime">日期及时间</div>
      </div>
      <div class="item" v-for="(file, index) in files" :key="index">
        <div class="select">
          <van-checkbox v-model="file.selected" @change="toggleSelect(file)"></van-checkbox>
        </div>
        <div class="filename">{{ file.filename }}</div>
        <div class="client">{{ file.client }}</div>
        <div class="datetime">{{ file.datetime }}</div>
      </div>
    </div>
    <div class="button-group">
      <van-button type="default" class="custom-button" @click="viewDetails">查看详情</van-button>
      <van-button type="default" class="custom-button" @click="downloadFiles">下载图纸</van-button>
      <van-button type="default" class="custom-button" @click="shareFiles">共享图纸</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchValue: '',
      activeTab: 0,
      selectAll: false,
      files: [
        { selected: false, filename: '文件A', client: '客户A', datetime: '2024-11-18 13:12:20' },
        { selected: false, filename: '文件B', client: '客户B', datetime: '2024-11-19 14:12:20' },
        { selected: false, filename: '文件C', client: '客户C', datetime: '2024-11-20 15:12:20' },
        { selected: false, filename: '文件D', client: '客户D', datetime: '2024-11-21 16:12:20' }
      ]
    };
  },
  methods: {
    toggleSelectAll(value) {
      this.files.forEach(file => (file.selected = value));
    },
    toggleSelect(file) {
      if (!this.selectAll && this.files.every(f => f.selected)) {
        this.selectAll = true;
      } else if (this.selectAll && !this.files.every(f => f.selected)) {
        this.selectAll = false;
      }
    },
    viewDetails() {
      console.log('查看详情');
    },
    downloadFiles() {
      console.log('下载图纸');
    },
    shareFiles() {
      console.log('共享图纸');
    }
  }
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
.search-container {
  padding: 10px;
}
.nav-container {
  padding: 10px;
}
.file-list {
  padding: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.select, .filename, .client, .datetime {
  flex: 1;
  text-align: center;
}
.button-group {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.custom-button {
  border: 1px solid #4caf50;
  color: #4caf50;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
}
</style>