<template>
  <div id="app" :key="componentKey">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadcrumbItems="breadcrumbItems" :isThumbnailView="isThumbnailView" :isFileDetailPage="false"
      @toggle-view-mode="toggleViewMode" />

    <!-- 文件搜索框 -->
    <div class="search-container">
      <van-search v-model="searchValue" placeholder="请输入搜索关键词" @input="onSearchInput" />
    </div>

    <!-- 移动端导航链接 -->
    <div class="nav-container">
      <van-tabs v-model="activeTab">
        <van-tab title="标签1"></van-tab>
        <van-tab title="标签2"></van-tab>
        <van-tab title="标签3"></van-tab>
        <van-tab title="标签4"></van-tab>
      </van-tabs>
    </div>

    <!-- 加载提示 -->
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner">加载中...</div>
    </div>

    <!-- 文件分类和文件列表 -->
    <div v-else class="file-container" :class="{ 'thumbnail-view': isThumbnailView, 'list-view': !isThumbnailView }">
      <div v-for="category in categories" :key="category.id" class="file-item"
        @click="category.isFile ? navigateToFile(category) : navigateToCategory(category)">
        <div class="icon-container">
          <van-icon v-if="!category.isFile" name="folder-o" class="icon-folder" />
          <van-icon v-else name="description" class="icon-file" />
        </div>
        <div class="file-name">{{ category.catName }}</div>
        <div class="customer-name">{{ category.custName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Search, Tabs, Tab, Icon } from 'vant';
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import { debounce } from 'lodash';
import Breadcrumb from './Breadcrumb.vue'; // 引入自定义面包屑导航组件

export default {
  components: {
    'van-search': Search,
    'van-tabs': Tabs,
    'van-tab': Tab,
    'van-icon': Icon,
    Breadcrumb // 注册自定义面包屑导航组件
  },
  props: {
    parentCatID: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      searchValue: '',
      activeTab: 0,
      categories: [],
      breadcrumbItems: [], // 用于存储面包屑导航项
      userId: JSON.parse(localStorage.getItem("user"))?.id, // 假设用户ID为1，实际应用中应从登录状态获取
      componentKey: 0, // 用于强制重新渲染
      isThumbnailView: JSON.parse(localStorage.getItem("isThumbnailView")) ?? true, // 从 localStorage 加载 isThumbnailView 状态
      isFetching: false, // 用于防止重复请求
      loading: false, // 添加加载状态标志
      debouncedFetchCategoriesByKeyword: debounce(this.fetchCategoriesByKeyword, 500) // 500 毫秒的防抖时间
    };
  },
  created() {
    this.parentCatID = parseInt(this.$route.query.parentCatID) || 0;
    this.fetchData();
    this.setupUserWatcher();
  },
  beforeRouteUpdate(to, from, next) {
    console.log('Before route update:', to, from); // 添加日志输出
    this.searchValue = '';
    this.parentCatID = parseInt(to.query.parentCatID) || 0;
    this.fetchData();
    next();
  },
  watch: {
    $route(to, from) {
      console.log('Route changed:', to, from); // 添加日志输出
      this.parentCatID = parseInt(to.query.parentCatID);
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      if (this.isFetching) return; // 防止重复请求
      this.isFetching = true;
      this.loading = true; // 开始加载时设置 loading 为 true

      this.fetchCategories()
        .then(() => this.fetchBreadcrumb())
        .finally(() => {
          this.isFetching = false;
          this.loading = false; // 结束加载时设置 loading 为 false
          this.componentKey += 1; // 强制重新渲染
        });
    },
    fetchCategories() {
      const params = {
        userid: this.userId,
        parentCatID: this.parentCatID,
        search: this.searchValue
      };
      console.log('Fetching categories with params:', params); // 添加日志输出
      return axios.get('/categorytree', { params })
        .then(response => {
          console.log('Fetched categories:', response.data); // 添加日志输出
          this.categories = response.data;
          this.$nextTick(() => {
            console.log('DOM updated after fetching categories'); // 添加日志输出
          });
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        });
    },
    fetchCategoriesByKeyword() {
      const params = {
        userid: this.userId,
        keyword: this.searchValue
      };
      console.log('Fetching categories by keyword with params:', params); // 添加日志输出
      return axios.get('/CategorytreeByKeyword', { params })
        .then(response => {
          console.log('Fetched categories by keyword:', response.data); // 添加日志输出
          this.categories = response.data;
          this.$nextTick(() => {
            console.log('DOM updated after fetching categories by keyword'); // 添加日志输出
          });
        })
        .catch(error => {
          console.error('Error fetching categories by keyword:', error);
        });
    },
    onSearchInput() {
      if (this.searchValue.trim()) {
        this.debouncedFetchCategoriesByKeyword();
      } else {
        this.fetchCategories();
      }
    },
    navigateToCategory(category) {
      this.$router.push({ path: '/CategoryList', query: { parentCatID: parseInt(category.id) } });
    },
    navigateToFile(file) {
      this.$router.push({ name: 'FileInfo', params: { fileId: parseInt(file.id) } });
    },
    toggleViewMode() {
      this.isThumbnailView = !this.isThumbnailView;
      localStorage.setItem("isThumbnailView", this.isThumbnailView); // 保存 isThumbnailView 状态到 localStorage
      // 可以在这里添加更多逻辑，例如更新文件列表的显示方式
    },
    async fetchBreadcrumb() {
      try {
        const params = {
          userid: this.userId,
          categoryId: this.parentCatID
        };
        const response = await axios.get('/breadcrumb', { params });
        console.log('Fetched breadcrumb items:', response.data); // 添加日志输出
        this.breadcrumbItems = response.data;
      } catch (error) {
        console.error('Error fetching breadcrumb items:', error);
      }
    },
    setupUserWatcher() {
      this.$store.watch(
        (state) => state.user,
        (newUser, oldUser) => {
          console.log('User state changed:', newUser, oldUser); // 添加日志输出
          if (newUser && newUser.id !== oldUser?.id) {
            this.userId = JSON.parse(localStorage.getItem("user"))?.id;
            this.fetchData();
          }
        }
      );
    }
  }
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.search-container {
  padding: 10px;
}

.nav-container {
  padding: 10px;
}

.file-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  cursor: pointer;
  margin: 10px;
  width: 100px;
  /* 根据需要调整宽度 */
}

.icon-container {
  margin-bottom: 10px;
}

.icon-folder,
.icon-file {
  font-size: 48px;
  /* 调整图标大小 */
}

.file-name {
  text-align: center;
  font-size: 14px;
}

.customer-name {
  font-size: 12px;
  color: #666;
}

.thumbnail-view .file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  cursor: pointer;
  margin: 10px;
  width: 100px;
  /* 根据需要调整宽度 */
}

.list-view .file-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
}

.list-view .icon-container {
  margin-right: 10px;
}

.list-view .icon-folder,
.list-view .icon-file {
  font-size: 24px;
  /* 调整图标大小 */
}

.list-view .file-name {
  text-align: left;
}

.list-view .customer-name {
  position: absolute;
  right: 5%;
  width:20%;
  overflow:hidden;
}

/* 加载提示样式 */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  font-size: 18px;
  color: #333;
}</style>