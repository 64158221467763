<template>
  <div id="app">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner">加载中...</div>
    </div>
    <!-- 移除 view-mode-toggle -->
    <Breadcrumb :breadcrumbItems="breadcrumbItems" :isThumbnailView="isThumbnailView" :isFileDetailPage="true" />
    <div class="header-container">
      <img :src="thumbnailBase64 || defaultThumbnail" alt="文件缩略图" @click="showFullscreenImage">
    </div>
    <FullscreenImage v-if="showFullscreen" :image-url="originalImageUrl" @close="showFullscreen = false" />
    <div class="content-container fileinfo">
      <van-collapse v-model="activeNames" accordion>
      <van-collapse-item
        :title="'文件名：' + fileNameWithoutExtension + '   材质：' + file.textureName + '   板厚：' + file.plateThick" name="1"
        key="1" style="text-align: left;">
      </van-collapse-item>
      <van-collapse-item title="文件信息" name="2" style="text-align: left;">
        <ul class="item-list">
          <li v-for="(item, key) in fileInfoList" :key="key">
            {{ item.label }}：<span>{{ item.value }}</span>
          </li>
        </ul>
      </van-collapse-item>
      <van-collapse-item title="文件版本" name="3" style="text-align: left;">
        <table class="version-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>版本号</th>
              <th>创建人</th>
              <th>创建时间</th>
              <th>说明</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="version in versionHistory" :key="version.id">
              <td>{{ version.id }}</td>
              <td>{{ version.version }}</td>
              <td>{{ version.userName }}</td>
              <td>{{ version.adate }}</td>
              <td>{{ version.memo1 }}</td>
            </tr>
          </tbody>
        </table>
      </van-collapse-item>
      <van-collapse-item title="相关文件" name="4" style="text-align: left;">
        <table class="version-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>文件名称</th>
              <th>版本号</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in relatedFiles" :key="file.id">
              <td>{{ file.id }}</td>
              <td>{{ fileBaseName(file.rfname) }}</td>
              <td>{{ file.rversion }}</td>
            </tr>
          </tbody>
        </table>
      </van-collapse-item>
      <van-collapse-item title="操作日志" name="5" style="text-align: left;">
        <ul class="item-list">
          <li v-for="(item, index) in operationLogItems" :key="index">
            <img :src="item.image" alt="操作日志图片">
            <span>{{ item.text }}</span>
          </li>
        </ul>
      </van-collapse-item>
      <van-collapse-item title="备注" name="6" style="text-align: left;">
        <div v-if="!isEditing" @click="isEditing = true;">
          {{ this.file.memo1 || "暂无内容" }}
        </div>
        <textarea v-else v-model="note" @blur="isEditing = false" @keyup.enter="isEditing = false"
          @focus="onFocus"></textarea>
      </van-collapse-item>
    </van-collapse>
    </div>
  </div>
</template>

<script>
import { Collapse, CollapseItem, Icon } from 'vant';
import axios from 'axios';
import FullscreenImage from './FullscreenImage.vue';
import Breadcrumb from './Breadcrumb.vue'; // 引入自定义面包屑导航组件

export default {
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    FullscreenImage,
    [Icon.name]: Icon,
    Breadcrumb // 注册自定义面包屑导航组件
  },
  data() {
    return {
      activeNames: ['2'], // 默认展开文件信息项
      file: {},
      fileInfoList: [],
      relatedFiles: [],
      versionHistory: [],
      operationLogItems: [],
      remarkItems: [],
      thumbnailBase64: '',
      isEditing: false,
      note: '',
      showFullscreen: false,
      defaultThumbnail: require('@/assets/default-thumbnail.png'), // 默认缩略图路径
      originalImageUrl: '', // 新增原始图片 URL 存储属性
      loading: false, // 加载状态
      breadcrumbItems: [], // 用于存储面包屑导航项
      isThumbnailView: JSON.parse(localStorage.getItem("isThumbnailView")) ?? true // 从 localStorage 加载 isThumbnailView 状态
    };
  },
  computed: {
    fileBaseName() {
      return this.file.fname ? this.basename(this.file.fname) : '';
    },
    fileNameWithoutExtension() {
      return this.file.fname ? this.basename(this.file.fname) : '';
    }
  },
  created() {
    // 在组件创建时预加载数据
    this.fetchData();
    this.fetchBreadcrumb();
  },
  mounted() {
    // 在页面内容加载完成后设置加载状态
    this.loading = true;
  },
  activated() {
    // 当组件被激活时重新获取数据
    this.fetchData();
    this.fetchBreadcrumb();
  },
  watch: {
    $route(to, from) {
      console.log('Route changed:', to, from); // 添加日志输出
      this.showFullscreen = false;
      this.originalImageUrl = '';
    }
  },
  methods: {
    async fetchData() {
      try {
        this.thumbnailBase64 = ''; // 先清空缩略图
        const fileId = this.$route.params.fileId; // 假设文件ID通过路由参数传递
        await this.getThumbnail(fileId);
        await this.getOriginalImage(fileId); // 获取原始图片 URL
        await this.getFileInfoItems(fileId);
        await this.getVersionHistory(fileId);
        await this.getOperationLogItems(fileId);
        await this.getRelatedFiles(fileId);
        await this.getRemarkItems(fileId);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false; // 结束加载
      }
    },
    async getThumbnail(fileId) {
      try {
        const response = await axios.get(`/api/FileDetails/${fileId}/thumbnail`);
        this.thumbnailBase64 = response.data.thumbnailBase64;
      } catch (error) {
        console.error('Error fetching thumbnail:', error);
      }
    },
    async generateThumbnail() {
      try {
        const response = await this.safeAxiosGet(`/api/FileDetails/${this.file.id}/thumbnail`);
        this.thumbnailBase64 = response.data.thumbnailBase64;
      } catch (error) {
        console.error('生成缩略图失败:', error);
      }
    },
    async getOriginalImage(fileId) {
      try {
        const response = await axios.get(`/api/FileDetails/${fileId}/originalimage`);
        this.originalImageUrl = response.data.thumbnailBase64;
      } catch (error) {
        console.error('Error fetching original image URL:', error);
      }
    },
    showFullscreenImage() {
      this.showFullscreen = true;
    },
    async showCustomAlert(message) {
      if (message) {
        this.alertMessage = message;
      }
    },
    async getFileInfoItems(fileId) {
      try {
        const response = await this.safeAxiosGet(`/api/filedetails/${fileId}`, '获取文件详情失败，请稍后再试。');
        this.file = response.data;
        this.note = response.data.memo1 || "暂无内容";
        this.fileInfoList = [
          { label: '文件名称', value: this.fileBaseName },
          { label: '版本', value: this.file.version },
          { label: '大小', value: this.file.bytes },
          { label: '创建人', value: this.file.userName },
          { label: '客户', value: this.file.custName },
          { label: '自定义版本', value: this.file.udefinedVersion },
          { label: '创建日期', value: this.file.adate },
          { label: '单据号', value: this.file.invoiceNo },
          { label: '工艺路线', value: this.file.cutmethodName },
          { label: '材质', value: this.file.textureName },
          { label: '板厚', value: this.file.plateThick },
          { label: '特殊要求', value: this.file.specialInstructions },
          { label: '折弯方向', value: this.file.bendingDirection },
          { label: '压延方向', value: this.file.calenderingDirection }
        ];
        if (this.file.id) {
          await this.generateThumbnail();
        }
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取文件详情失败，请稍后再试。');
      }
    },
    async getVersionHistory(fileId) {
      try {
        const response = await this.safeAxiosGet(`/api/filedetails/${fileId}/versions`, '获取文件详情失败，请稍后再试。');
        this.versionHistory = response.data;
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取文件版本历史失败，请稍后再试。');
      }
    },
    async getRelatedFiles() {
      try {
        const fileId = this.$route.params.id;
        const response = await this.safeAxiosGet(`/api/filedetails/${fileId}/related`, '获取文件详情失败，请稍后再试。');
        this.relatedFiles = response.data;
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取相关文件失败，请稍后再试。');
      }
    },
    async getOperationLogItems(fileId) {
      try {
        const response = await axios.get(`/api/FileDetails/${fileId}/logs`);
        this.operationLogItems = response.data.map(item => ({
          image: item.imageUrl,
          text: item.logText
        }));
      } catch (error) {
        console.error('Error fetching operation log items:', error);
      }
    },
    async getRemarkItems(fileId) {
      try {
        const response = await axios.get(`/api/FileDetails/${fileId}/remarks`);
        this.remarkItems = response.data.map(item => ({
          image: item.imageUrl,
          text: item.remarkText
        }));
      } catch (error) {
        console.error('Error fetching remark items:', error);
      }
    },
    async fetchBreadcrumb() {
      try {
        const fileId = this.$route.params.fileId;
        const fileResponse = await this.safeAxiosGet(`/api/filedetails/${fileId}`, '获取文件详情失败，请稍后再试。');
        const catID = fileResponse.data.catID;
        const params = {
          userid: JSON.parse(localStorage.getItem("user"))?.id,
          categoryId: catID
        };
        const breadcrumbResponse = await axios.get('/breadcrumb', { params });
        console.log('Fetched breadcrumb items:', breadcrumbResponse.data);
        this.breadcrumbItems = breadcrumbResponse.data;
      } catch (error) {
        console.error('Error fetching breadcrumb items:', error);
      }
    },
    safeAxiosGet(url, errorMessage, config = {}) {
      return axios.get(url, {
        ...config,
        headers: {
          'X-CSRF-Token': this.getCsrfToken(),
          ...config.headers
        }
      }).catch(error => {
        console.error(errorMessage, error);
        throw new Error(errorMessage);
      });
    },
    safeAxiosDelete(url, errorMessage) {
      return axios.delete(url, {
        headers: {
          'X-CSRF-Token': this.getCsrfToken()
        }
      }).catch(error => {
        console.error(errorMessage, error);
        throw new Error(errorMessage);
      });
    },
    getCsrfToken() {
      // 假设 CSRF 令牌存储在 localStorage 中
      return localStorage.getItem('csrfToken');
    },
    onFocus() {
      if (this.note === '暂无内容') {
        this.note = '';
      }
    },
    basename(filename) {
      return filename.split('.').slice(0, -1).join('.');
    },
    toggleViewMode() {
      this.isThumbnailView = !this.isThumbnailView;
      localStorage.setItem("isThumbnailView", this.isThumbnailView); // 保存 isThumbnailView 状态到 localStorage
      this.componentKey += 1; // 强制重新渲染
    }
  }
};
</script>


<style scoped>
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  text-align: center;
  width: 100%;
  position: relative;
}

.header-container {
  height: 33.33vh;
  /* 屏幕高度的 1/3 */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  /* 背景颜色可自定义 */
}

.header-container img {
  max-width: 100%;
  max-height: 100%;
}

.content-container {
  width: 100%;
  padding: 10px;
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item-list li {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.item-list li img {
  max-width: 50px;
  max-height: 50px;
  margin-right: 10px;
}

.fileinfo .collapse-item-title {
  text-align: left;
  /* 假设.collapse-item-title是折叠项标题的类名 */
}

/* 新增样式：使标题向左对齐 */
.van-collapse-item__title {
  text-align: left !important;
}

/* 新增表格样式 */
.version-table {
  width: 100%;
  border-collapse: collapse;
}

.version-table th,
.version-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.version-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* 加载提示样式 */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  font-size: 18px;
  color: #333;
}
</style>