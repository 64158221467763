<template>
  <div id="app">
    <div class="header-container">
      <h1>注册</h1>
    </div>
    <div class="input-container">
      <van-field v-model="username" label="用户名" placeholder="请输入用户名"></van-field>
      <van-field v-model="password" label="密码" placeholder="请输入密码" type="password"></van-field>
      <van-field v-model="confirmPassword" label="确认密码" placeholder="请再次输入密码" type="password"></van-field>
    </div>
    <div class="button-container">
      <van-button type="primary" @click="handleSubmit">注册</van-button>
    </div>
  </div>
</template>

<script>
import { Field, Button } from 'vant';
import axios from 'axios';
import { ref } from 'vue';

const username = ref('');
const password = ref('');
const confirmPassword = ref('');
const phone = ref('');
const error = ref('');
const success = ref('');
export default {
  components: {
    'van-field': Field,
    'van-button': Button
  },
  data() {
    return {
      username: '',
      password: '',
      confirmPassword: ''
    };
  },
  methods: {
    async handleSubmit() {
      error.value = '';
      success.value = '';

      if (password.value !== confirmPassword.value) {
        error.value = '两次输入的密码不一致';
        return;
      }

      try {
        const response = await axios.post('/api/register', {
          username: username.value,
          password: password.value,
          confirmPassword: confirmPassword.value,
          phone: phone.value
        });

        success.value = '注册成功';
        setTimeout(() => {
          this.$router.push('/login');
        }, 2000);
      } catch (err) {
        if (err.response) {
          error.value = err.response.data.message;
        } else {
          error.value = '注册时发生错误，请稍后再试';
        }
      }
    }
  }
};
</script>

<style scoped>
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

#app {
  text-align: center;
  width: 100%;
}

.header-container {
  height: 33.33vh;
  /* 屏幕高度的 1/3 */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  /* 背景颜色可自定义 */
}

.header-container .van-nav-bar__title {
  font-size: 30px;
  /* 设置标题字体大小 */
}

.input-container {
  width: 90%;
  margin: 20px 0;
}

.button-container {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  /* 使按钮容器占据整个宽度 */
}

.button-container .van-button {
  width: 95%;
  /* 按钮宽度为屏幕宽度的 95% */
  text-align: center;
  background-color: #00008B;
  /* 深蓝色背景 */
  color: white;
  /* 文字颜色为白色 */
}
</style>