<template>
  <div v-if="showFullscreen" class="fullscreen-image-container" @click="closeFullscreen">
    <img 
      :src="imageUrl" 
      alt="Full Screen Image" 
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      :style="{ transform: `scale(${scale})` }"
    >
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showFullscreen: true,
      scale: 1,
      initialScale: 1,
      lastDistance: null,
      isPinching: false,
    };
  },
  methods: {
    handleTouchStart(event) {
      if (event.touches.length === 2) {
        this.isPinching = true;
        this.lastDistance = this.getDistance(event.touches[0], event.touches[1]);
        this.initialScale = this.scale;
      }
    },
    handleTouchMove(event) {
      if (this.isPinching && event.touches.length === 2) {
        const currentDistance = this.getDistance(event.touches[0], event.touches[1]);
        const delta = currentDistance - this.lastDistance;
        this.scale = Math.max(1, Math.min(5, this.initialScale + delta * 0.01)); // 调整缩放因子
        this.lastDistance = currentDistance;
      }
    },
    handleTouchEnd(event) {
      if (event.touches.length === 0) {
        this.isPinching = false;
      }
    },
    getDistance(touch1, touch2) {
      const dx = touch1.clientX - touch2.clientX;
      const dy = touch1.clientY - touch2.clientY;
      return Math.sqrt(dx * dx + dy * dy);
    },
    closeFullscreen() {
      this.$emit('close');
    },
  }
};
</script>

<style scoped>
.fullscreen-image-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-image-container img {
  max-width: 100%;
  max-height: 100%;
  user-select: none;
  touch-action: none;
  transition: transform 0.1s ease;
}
</style>